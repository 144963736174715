


<template>
   <DocNav />




    <div class="VPContent has-sidebar" id="VPContent" data-v-9d8abc1e="" data-v-3cf691b6="" style="color:black;text-align:left;">
        <div class="VPDoc has-sidebar has-aside" data-v-3cf691b6="" data-v-a3c25e27="">
            <div class="container" data-v-a3c25e27="">
                <!--<PageAside />-->




                <div class="content" data-v-a3c25e27="">
                    <div class="content-container" data-v-a3c25e27="">


                        <RigidConstraintRender />



                        <footer class="VPDocFooter" data-v-a3c25e27="" data-v-a2d931e4="">



                            <nav class="prev-next" data-v-a2d931e4="">
                                <div class="pager" data-v-a2d931e4="">
                                    <a class="pager-link prev" href="/#/docs/rigid_render"
                                    data-v-a2d931e4="">
                                        <span class="desc" data-v-a2d931e4="">
                                           前一页 
                                        </span>
                                        <span class="title" data-v-a2d931e4="">
                                          如何渲染刚体 
                                        </span>
                                    </a>
                                </div>
                                <div class="pager" data-v-a2d931e4="">
                                    <a class="pager-link next" href="/#/docs/soft_render"
                                    data-v-a2d931e4="">
                                        <span class="desc" data-v-a2d931e4="">
                                           下一页 
                                        </span>
                                        <span class="title" data-v-a2d931e4="">
                                           如何渲染软体
                                        </span>
                                    </a>
                                </div>
                            </nav>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import DocNav from "../../components/DocNav.vue";
import PageAside from "../../components/PageAside.vue";
import RigidConstraintRender from "../../components/RigidConstraintRender.vue";
export default {
    components: {
        DocNav,
        PageAside,
        RigidConstraintRender,
    },
    head(){
      return {
        title: "BlendStation Render | Download"
      }
    }
  }
</script>



<style lang="scss" scoped>
        @import "../../assets/css/doc.scss";
</style>
