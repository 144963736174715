<template>
                <p><strong>如何渲染刚体</strong></p>
                <p>&emsp;  BlendStation渲染集群支持刚体渲染，有很多第一次接触渲染农场(渲染集群)的blender用户在刚体渲染的时候会遇到一些问题，比如在Blender软件界面可以渲染出来物体运动但是在渲染集群里渲染却是静态的，造成这些问题的主要原因是Blender用户在提交blend工程文件的时候忘记进行烘焙，这会导致渲染的时候缺乏相关的运动数据从而导致渲染出来的是静态数据，那么如何进行刚体烘焙？<br><br>
                </p>
                <ol>
                     <li>打开blender软件，进入场景属性面板<br>
                     </li>
                     <li>进入"刚体世界环境"设置<br>
                         <img decoding="async" src="@/assets/img/rigid/scene-rigidworld.png">
                     </li>
                     <li>点击"烘焙所有动力学结算结果"</li>
                          <img decoding="async" src="@/assets/img/rigid/rigid-bake.png">
               
                     <li>烘焙完后保存blend文件，然后提交到BlendStaion-Manager<br>
                     </li>
                </ol>
</template>
