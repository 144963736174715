
<template>
    <aside class="VPSidebar" data-v-9d8abc1e="" data-v-ee2efba5=""   style="color:black;text-align:left;">
        <div class="curtain" data-v-ee2efba5=""><br><br><br></div>
        <nav class="nav" id="VPSidebarNav" aria-labelledby="sidebar-aria-label" tabindex="-1" data-v-ee2efba5="">
            <span class="visually-hidden" id="sidebar-aria-label" data-v-ee2efba5="">
                Sidebar Navigation
            </span>


            <div class="group" data-v-ee2efba5="">
                <section class="VPSidebarItem level-0 has-active" data-v-ee2efba5="" data-v-bd01e0d5="">
                    <div class="item" role="button" tabindex="0" data-v-bd01e0d5="">
                        <div class="indicator" data-v-bd01e0d5="">
                        </div>
                        <h2 class="text" data-v-bd01e0d5="" >
                           导引 
                        </h2>
                    </div> 

                    <div class="items" data-v-bd01e0d5="">

                        <div class="VPSidebarItem level-1 is-link" data-v-bd01e0d5="">
                            <div class="item" data-v-bd01e0d5="">
                                <div class="indicator" data-v-bd01e0d5="">
                                </div>
                                <a class="VPLink link link" href="/#/docs/introduce"
                                data-v-bd01e0d5="">
                                    <p class="text" data-v-bd01e0d5="">
                                        快速开始
                                    </p>
                                </a>
                            </div>
                        </div>


                        <section class="VPSidebarItem level-1 " data-v-bd01e0d5="">
                            <div class="item" role="button" tabindex="0" data-v-bd01e0d5="">
                                <div class="indicator" data-v-bd01e0d5="">
                                </div>
                                <h3 class="text" data-v-bd01e0d5="">
                                   软件安装 
                                </h3>
 

                                <button @click="isExpanded1 = !isExpanded1; ">
                                    <p v-if="isExpanded1">    

                                        <div class="caret" role="button" aria-label="toggle section" tabindex="0"  data-v-bd01e0d5="">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" viewBox="0 0 24 24" class="caret-icon" data-v-bd01e0d5="" style="transform:rotate(90deg);">
                                                <path d="M9,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C9.5,18.9,9.3,19,9,19z">
                                                </path>
                                            </svg>
                                        </div>

                                    </p>
                                    <p v-if="!isExpanded1">
                                        <div class="caret" role="button" aria-label="toggle section" tabindex="0"  data-v-bd01e0d5="">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" viewBox="0 0 24 24" class="caret-icon" data-v-bd01e0d5="" style="transform:rotate(0deg);">
                                                <path d="M9,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C9.5,18.9,9.3,19,9,19z">
                                                </path>
                                            </svg>
                                        </div>
                                    </p>

                                </button>
                            </div>


                            <Collapse :when="isExpanded1">

                            <div class="items" data-v-bd01e0d5="">
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/condition" data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               安装前置条件 
                                            </p>
                                        </a>
                                    </div>
                                </div>


                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/windows" data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               Windows平台安装 
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/linux" data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                                Linux平台安装
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/macos"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                                MacOS平台安装
                                            </p>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            </Collapse>
                        </section>




                        <section class="VPSidebarItem level-1 " data-v-bd01e0d5="">
                            <div class="item" role="button" tabindex="0" data-v-bd01e0d5="">
                                <div class="indicator" data-v-bd01e0d5="">
                                </div>
                                <h3 class="text" data-v-bd01e0d5="">
                                   软件使用 
                                </h3>

                                <button @click="isExpanded2 = !isExpanded2; ">
                                    <p v-if="isExpanded2">    

                                        <div class="caret" role="button" aria-label="toggle section" tabindex="0"  data-v-bd01e0d5="">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" viewBox="0 0 24 24" class="caret-icon" data-v-bd01e0d5="" style="transform:rotate(90deg);">
                                                <path d="M9,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C9.5,18.9,9.3,19,9,19z">
                                                </path>
                                            </svg>
                                        </div>

                                    </p>
                                    <p v-if="!isExpanded2">
                                        <div class="caret" role="button" aria-label="toggle section" tabindex="0"  data-v-bd01e0d5="">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" viewBox="0 0 24 24" class="caret-icon" data-v-bd01e0d5="" style="transform:rotate(0deg);">
                                                <path d="M9,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C9.5,18.9,9.3,19,9,19z">
                                                </path>
                                            </svg>
                                        </div>
                                    </p>

                                </button>



                            </div>


                            <Collapse :when="isExpanded2">
                            <div class="items" data-v-bd01e0d5="">
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/machine_manage" data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               机器管理 
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/job_manage"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               作业管理 
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/run_info"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                                运行监控
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/output"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               查看渲染结果 
                                            </p>
                                        </a>
                                    </div>
                                </div>

                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/batch_manage"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               批量管理 
                                            </p>
                                        </a>
                                    </div>
                                </div>



                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/composite"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                               合成渲染 
                                            </p>
                                        </a>
                                    </div>
                                </div>



                            </div>
                            </Collapse>
                        </section>





                        <section class="VPSidebarItem level-1 " data-v-bd01e0d5="">
                            <div class="item" role="button" tabindex="0" data-v-bd01e0d5="">
                                <div class="indicator" data-v-bd01e0d5="">
                                </div>
                                <h3 class="text" data-v-bd01e0d5="">
                                   其他 
                                </h3>

                                <button @click="isExpanded3 = !isExpanded3; ">
                                    <p v-if="isExpanded3">    

                                        <div class="caret" role="button" aria-label="toggle section" tabindex="0"  data-v-bd01e0d5="">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" viewBox="0 0 24 24" class="caret-icon" data-v-bd01e0d5="" style="transform:rotate(90deg);">
                                                <path d="M9,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C9.5,18.9,9.3,19,9,19z">
                                                </path>
                                            </svg>
                                        </div>

                                    </p>
                                    <p v-if="!isExpanded3">
                                        <div class="caret" role="button" aria-label="toggle section" tabindex="0"  data-v-bd01e0d5="">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" viewBox="0 0 24 24" class="caret-icon" data-v-bd01e0d5="" style="transform:rotate(0deg);">
                                                <path d="M9,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C9.5,18.9,9.3,19,9,19z">
                                                </path>
                                            </svg>
                                        </div>
                                    </p>

                                </button>



                            </div>


                            <Collapse :when="isExpanded3">
                            <div class="items" data-v-bd01e0d5="">


                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/config"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              服务配置自定义 
                                            </p>
                                        </a>
                                    </div>
                                </div>

                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/cycles_device"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              Cycles计算设备自定义
                                            </p>
                                        </a>
                                    </div>
                                </div>

                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/rigid_render"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何渲染刚体
                                            </p>
                                        </a>
                                    </div>
                                </div>

                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/rigid_constraint_render"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何渲染刚体约束
                                            </p>
                                        </a>
                                    </div>
                                </div>



                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/soft_render"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何渲染软体
                                            </p>
                                        </a>
                                    </div>
                                </div>



                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/cloth_render"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何渲染布料
                                            </p>
                                        </a>
                                    </div>
                                </div>


                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/particle_emitter_render"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何渲染粒子系统
                                            </p>
                                        </a>
                                    </div>
                                </div>





                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/volume_render"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何渲染体积云
                                            </p>
                                        </a>
                                    </div>
                                </div>


                                <div class="VPSidebarItem level-2 is-link" data-v-bd01e0d5="">
                                    <div class="item" data-v-bd01e0d5="">
                                        <div class="indicator" data-v-bd01e0d5="">
                                        </div>
                                        <a class="VPLink link link" href="/#/docs/farm_skill"  data-v-bd01e0d5="">
                                            <p class="text" data-v-bd01e0d5="">
                                              如何低成本建立渲染集群
                                            </p>
                                        </a>
                                    </div>
                                </div>




                            </div>
                            </Collapse>
                        </section>






                    </div>
                </section>
            </div>











        </nav>
    </aside>

</template>


<script >
import { ref } from 'vue'


export default {
    name: 'Doc',
    data(){
        return {
        
            isExpanded1: ref(false),
            isExpanded2: ref(false),
            isExpanded3: ref(false),
        }
    },
    mounted(){
         this.isExpanded1 = ref(true);
         this.isExpanded2 = ref(true);
         this.isExpanded3 = ref(true);
    },
}
</script>


<style lang="scss" scoped>
        @import "../assets/css/doc.scss";
</style>

