
<template>
<div class="video-background">
        <video  width=100%  autoplay="" loop="" muted="" poster="@/assets/video/preview2.jpg" src="@/assets/video/demo2.mp4">
haha</video>



   <div class = "conttent">
    <section class="hero download header-align-x-center header-align-y-center" width=100%>
           <div class="container" width=100%>
                <div class="hero-content" width=100%>
                        <h1 style="color:white;">
                                轻松搭建自己的渲染农场                   
                        </h1>
                        <div class="dl-buttons-container">
                             <div id="windows" class="dl-header-cta dl-os-windows">
                                   <a href="https://pan.baidu.com/s/1diJSIlhnQszvGBxOC-xTVw?pwd=4wfp" class="btn btn-accent dl-header-cta-button js-download" dl_stats="Windows Installer" title="为Windows平台下载BlendStation">
                                   	   <i class="i-windows"></i>
                                                                下载 BlendStation 3.9.5
                                   </a>
                                   <ul class="dl-build-details">
                                         <li class="os windows">
                                             <strong>Windows</strong>
                                         </li>
                                         <li>Installer</li>
                                         <li title="Tiny isn't?">1.4GB</li>
                                         <li class="popup-toggle js-toggle-menu" data-toggle-menu="menu-info-windows">
                                             <i class="i-info"></i>
                                         </li>
                                    </ul>
                                    <div class="dl-build-details-popup dl-togglable" id="menu-info-windows">
                                        <div class="platform-warning alert alert-warning"></div>
                                        <p>Compatible with Windows 11, 10, and 8.1</p>
                                        <p>Intel Arc supported with driver version 101.3430 or newer.</p>
                                        <small>Released on July 18, 2023 · </small>
                                        <small class="checksum">
                                            <a href="https://download.blender.org/release/Blender3.6/blender-3.6.1.md5">md5</a>
                                        </small>
                                        <small class="checksum">
                                            <a href="https://download.blender.org/release/Blender3.6/blender-3.6.1.sha256">sha256</a>
                                        </small>
                                    </div>
                                </div>

                                <div id="macos-apple-silicon" class="dl-header-cta dl-os-macos-apple-silicon">
                                      <a href="https://www.blender.org/download/release/Blender3.6/blender-3.6.1-macos-arm64.dmg/" class="btn btn-accent dl-header-cta-button js-download" dl_stats="macOS Apple Silicon" title="Download BlendStation for macOS Apple Silicon">
                                           <i class="i-macos-apple-silicon"></i>
                                                                下载 BlendStation 3.9.5
                                      </a>
                                      <ul class="dl-build-details">
                                           <li class="os macos-apple-silicon">
                                               <strong>macOS</strong>
                                           </li>
                                           <li>Apple Silicon</li>
                                           <li title="Tiny isn't?">238MB</li>
                                           <li class="popup-toggle js-toggle-menu" data-toggle-menu="menu-info-macos-apple-silicon">
                                                <i class="i-info"></i>
                                           </li>
                                      </ul>
                                      <div class="dl-build-details-popup dl-togglable" id="menu-info-macos-apple-silicon">
                                          <div class="platform-warning alert alert-warning"></div>
                                          <p>Compatible with Apple M1 and M2 processors.</p>
                                          <p>Requires macOS 11.0 (Big Sur) or newer.</p>
                                          <small>Released on July 18, 2023 · </small>
                                          <small class="checksum">
                                              <a href="https://download.blender.org/release/Blender3.6/blender-3.6.1.md5">md5</a>
                                          </small>
                                          <small class="checksum">
                                              <a href="https://download.blender.org/release/Blender3.6/blender-3.6.1.sha256">sha256</a>
                                          </small>
                                       </div>
                                 </div>

                                 <div id="linux" class="dl-header-cta dl-os-linux">
                                      <a href="https://pan.baidu.com/s/1cnw88xtsXFhc5emytg3kRQ?pwd=jjux" class="btn btn-accent dl-header-cta-button js-download" dl_stats="Linux "  title="为Linux平台下载BlendStation">
                                        <i class="i-linux"></i>
                                                                下载 BlendStation 3.9.5
                                      </a>
                                      <ul class="dl-build-details">
                                           <li class="os linux">
                                                <strong>Linux</strong>
                                           </li>
                                           <li title="Tiny isn't?">1.8GB</li>
                                           <li class="popup-toggle js-toggle-menu" data-toggle-menu="menu-info-linux">
                                                <i class="i-info"></i>
                                           </li>
                                      </ul>
                                      <div class="dl-build-details-popup dl-togglable" id="menu-info-linux">
                                           <div class="platform-warning alert alert-warning"></div>
                                           <p>Suits most recent GNU/Linux distributions using glibc 2.28 or newer.</p>
                                           <p>Intel Arc supported with driver version 101.3430 or newer.</p>
                                           <small>Released on July 18, 2023 · </small>
                                           <small class="checksum">
                                                <a href="https://download.blender.org/release/Blender3.6/blender-3.6.1.md5">md5</a>
                                           </small>
                                           <small class="checksum">
                                                <a href="https://download.blender.org/release/Blender3.6/blender-3.6.1.sha256">sha256</a>
                                           </small>
                                       </div>
                                 </div>
                            </div>

                            <div class="dl-header-other js-toggle-menu" data-toggle-menu="menu-other-platforms">
                                     <span class="ot windows">Windows,</span>
                                                        和其他版本 
                                           <i class="i-chevron-down"></i>
                            </div>                      
                               <ul class="dl-header-other-list dl-togglable" id="menu-other-platforms">
                                    <li class="os windows">
                                         <a class="js-download" href="https://pan.baidu.com/s/1diJSIlhnQszvGBxOC-xTVw?pwd=4wfp" dl_stats="Windows Installer" title="为Windows平台下载BlendStation">
                                              <span class="name">Windows版本</span>
                                              <span class="size">1.4 GB</span>
                                         </a>
                                    </li>
                                      <li class="separator"></li>
                                      <li class="os linux">
                                          <a class="js-download" href="https://pan.baidu.com/s/1cnw88xtsXFhc5emytg3kRQ?pwd=jjux" dl_stats="Linux " title="为Linux平台下载BlendStation">
                                               <span class="name">Linux版本</span>
                                               <span class="size">1.8 GB</span>
                                          </a>
                                      </li>
                               </ul>
                     </div>

             </div>

      <!--       <div class="hero-background-faded" width=100%>
                     <div class="hero-background-faded-image"  style="width=100%;" >  
       -->
                           <!--<img src="@/assets/img/bg1.jpg" style="width=100%;backgrond-size:cover;" />-->
<!--
<video width=100%  autoplay="" loop="" muted="" poster="https://public.rokidcdn.com/rokid-website-lite/poster/preview.jpg" src="https://public.rokidcdn.com/rokid-website-lite/video/preview.mp4"></video>
-->
<!--
                     </div>
             </div>
-->
            <div class="hero-overlay hero-overlay-bottom"></div>
            <div class="hero-overlay hero-overlay-top"></div>
    </section>
   </div>
  </div>
   <Footer />
</template>

<script setup>
import Footer from '../components/Footer.vue'
import {onMounted,onUpdated} from 'vue'
onUpdated(() => {
    console.error("Download Updated")
})

onMounted(() => {
    console.error("Download Mounted");
    zzz();
    //this.$forceUpdate();
    //window.location.reload();
})
function zzz(){
    console.error("sub zzz")

}
import * as jquery from '@/assets/js/jquery-1.9.1.min.js'
import {blenderOs} from '@/assets/js/blenderOs.js'
</script>





<!--<style scoped>-->
<style scoped lang="scss">
@import "../assets/css/bthree.css";

		.dl-os-windows { display: block important;}
		.dl-header-other-list{
			display: block !important;
			left: unset !important;
			opacity: 1 !important;
			position: initial !important;
			transform: unset !important;
			visibility: visible !important;
		}


.video-background {
  position: relative; 
  width: 100%; 
  height: 100vh; 
}
 
.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  position: absolute; 
  top: 0;
  left: 0;
}
 
.video-background .content {
  position: absolute; 
  top: 0; 
  left: 0; 
  color: white;
}


</style>


