<template>
                <p><strong>如何渲染布料</strong></p>
                <p>&emsp;  BlendStation渲染集群支持布料物理模拟场景渲染，有很多第一次接触渲染农场(渲染集群)的blender用户在渲染Blender布料的的时候会遇到一些奇怪的问题，比如布料在渲染集群里渲染穿过碰撞体，主要原因是由于Blender用户在提交blend工程文件的时候忘记进行布料相关的烘焙，这会导致渲染的时候缺乏相关的运动数据从而导致渲染出来与预期不一致的问题，那么如何进行布料烘焙？<br><br>
                </p>
                <ol>
                     <li>打开blender软件，进入物理属性面板中的布料属性<br>
                     </li>
                     <li>进入布料属性的缓存设置<br>
                         <img decoding="async" src="@/assets/img/cloth/physics-cloth.png">
                     </li>
                     <li>点击"烘焙所有动力学结算结果"</li>
                          <img decoding="async" src="@/assets/img/cloth/cloth-bake.png">
               
                     <li>烘焙完后保存blend文件，然后提交到BlendStaion-Manager<br>
                     </li>
                </ol>
</template>
