<template>
                <p><strong>如何渲染粒子系统</strong></p>
                <p>&emsp;  BlendStation渲染集群支持粒子系统渲染，，那么如何进行粒子系统渲染？<br><br>
                </p>
                <ol>
                     <li>打开blender软件，进入粒子属性面板中的发射体属性<br>
                     </li>
                     <li>进入发射体属性的缓存设置<br>
                         <img decoding="async" src="@/assets/img/particle/particle-emitter.png">
                     </li>
                     <li>点击"烘焙所有动力学结算结果"</li>
                          <img decoding="async" src="@/assets/img/particle/emitter-bake.png">
               
                     <li>烘焙完后保存blend文件，然后提交到BlendStaion-Manager<br>
                     </li>
                </ol>
</template>
